<template>
	<div id="aliyunOss" class="el-content">
		<a-form :label-col="{ span: 4}" :wrapper-col="{ span: 8 }">
			<a-form-item label="短信通道">
				<a-radio-group v-model:value="form.is_kd_sms">
					<a-radio :value="'0'">阿里云短信</a-radio>
					<a-radio :value="'1'">坤典短信</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="短信access_key_id"><a-input v-model:value="form.access_key_id"></a-input></a-form-item>
			<a-form-item label="短信access key secret"><a-input v-model:value="form.access_key_secret"></a-input></a-form-item>
			<a-form-item label="短信签名"><a-input v-model:value="form.sign"></a-input></a-form-item>
			<a-form-item label="验证码模板"><a-input v-model:value="form.template"></a-input></a-form-item>
			<a-form-item label="商家通知模板"><a-input v-model:value="form.note_store_apply_check"></a-input></a-form-item>
			<a-form-item label="店铺申请通知"><a-input v-model:value="form.note_store_apply_to_manager"></a-input></a-form-item>
			<a-form-item label="新订单通知"><a-input v-model:value="form.note_new_order"></a-input></a-form-item>
			<a-form-item label="订单售后处理通知"><a-input v-model:value="form.after_sale_order_temp"></a-input></a-form-item>
			<a-form-item label="商家订单售后处理通知"><a-input v-model:value="form.store_after_sale_order_temp"></a-input></a-form-item>
			<a-form-item :wrapper-col="{ offset:4 }"><a-button type="primary" @click="submitSave">保存</a-button></a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			form: {
				access_key_id: '',
				access_key_secret: '',
				sign: '',
				template: '',
				scene: 'sms',
				is_kd_sms: '',
				note_store_apply_check: '',
				note_store_apply_to_manager: '',
				note_new_order: '',
				after_sale_order_temp: '',
				store_after_sale_order_temp: ''
			}
		})

		saasSetModel.getWebConfigSite(['sms_json'],res=>{
			state.form = tool.filterForm(Object.keys(state.form),res.sms_json)
		})

		const submitSave = ()=>{
			let form = JSON.parse(JSON.stringify(state.form))
			form.scene = 'sms'
			saasSetModel.saveWebConfigSite(form)
		}

		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style>
</style>
